<template>
  <tr data-cy="employee-row" @click.stop="editEmployment">
    <td>
      <user-avatar :user="employment"></user-avatar>
    </td>
    <td data-cy="employee-name">
      {{ employment.full_name || employment.user.full_name }}
      <br />
      <span class="tw-text-gray-600">{{ employment.email }}</span>
    </td>
    <td>
      {{ departmentName }}
    </td>
    <td>
      <div class="tw-flex tw-items-center">
        <div
          :class="employmentStatusColor"
          class="tw-px-2 tw-py-2 tw-h-8 tw-leading-none tw-text-white tw-text-center tw-font-semibold tw-text-sm tw-rounded-full"
          style="min-width: 80px;"
          data-cy="btn-employee-status"
        >
          {{ employment.status }}
        </div>
        <div v-if="!isTrashed && !hasJoinedCompany" class="tw-ml-4">
          <SpinnerButton
            v-if="isInvited && hasEmployeeEmail"
            :disabled="isInviting"
            :loading="isInviting"
            :spinner-only="true"
            :spinner-classes="['tw-h-2 tw-w-2 tw-text-blue-500']"
            as="link"
            class="tw-p-0 tw-text-sm"
            type="button"
            data-cy="btn-employee-invite"
            @click="$emit('remind-invitation')"
          >
            Remind
          </SpinnerButton>

          <SpinnerButton
            v-if="!isInvited && hasEmployeeEmail"
            :disabled="isInviting"
            :loading="isInviting"
            :spinner-only="true"
            :spinner-classes="['tw-h-2 tw-w-2 tw-text-blue-500']"
            as="link"
            class="tw-p-0 tw-text-sm"
            type="button"
            data-cy="btn-employee-invite"
            @click="$emit('invite-employment')"
          >
            Invite
          </SpinnerButton>

          <button
            v-if="!hasEmployeeEmail"
            class="btn btn-link tw-p-0 tw-text-sm"
            type="button"
            data-cy="add-email-address"
            @click.stop="$emit('edit-employment')"
          >
            Add email address
          </button>
        </div>
      </div>
    </td>
    <td>
      <div class="tw-flex tw-items-end tw-justify-end">
        <button
          :disabled="isTrashed"
          class="btn btn-icon tw-border tw-border-gray-400 hover:tw-bg-gray-300 tw-ml-3"
          title="Edit"
          data-cy="edit-employee"
          @click.stop="$emit('edit-employment')"
        >
          <div class="tw-flex tw-items-center tw-justify-center">
            <svg-icon name="edit-pencil" class="svg-icon tw-text-gray-700" />
          </div>
        </button>
        <DisabledElementWithTitle
          :title="isActiveEmployment ? 'You can not delete yourself' : ''"
        >
          <button
            v-if="!isTrashed"
            :disabled="isActiveEmployment"
            class="btn btn-icon tw-border tw-bg-red-500 hover:tw-bg-red-500 tw-border-red-500 tw-ml-3"
            title="Delete"
            data-cy="delete-employee"
            @click.stop="$emit('delete-employment')"
          >
            <div class="tw-flex tw-items-center tw-justify-center">
              <svg-icon name="trash" class="svg-icon tw-text-white" />
            </div>
          </button>
        </DisabledElementWithTitle>
        <button
          v-if="isTrashed"
          class="btn btn-icon tw-border tw-bg-red-500 hover:tw-bg-red-500 tw-border-red-500 tw-ml-3"
          title="Restore"
          data-cy="restore-employee"
          @click="() => $emit('restore-employment')"
        >
          <div class="tw-flex tw-items-center tw-justify-center">
            <svg-icon
              name="restore"
              class="svg-icon tw-text-white"
              style="position: relative; left: -1px;"
            />
          </div>
        </button>
      </div>
    </td>
  </tr>
</template>
<script>
import DisabledElementWithTitle from '@/components/DisabledElementWithTitle'
import SpinnerButton from '@/components/SpinnerButton'

export default {
  name: 'EmployeeTableRow',

  components: { DisabledElementWithTitle, SpinnerButton },

  props: {
    employment: {
      type: Object,
      required: true,
    },

    invitingEmployment: {
      type: Object,
      default: null,
    },
  },

  computed: {
    departmentName() {
      if (!this.employment.department) {
        return
      }

      return this.employment.department.name
    },

    isActiveEmployment() {
      return this.employment.id === this.activeEmployment.id
    },

    hasJoinedCompany() {
      return this.employment.status === 'Joined'
    },

    isInvited() {
      return this.employment.status === 'Invited'
    },

    hasEmployeeEmail() {
      return this.employment.email !== null
    },

    employmentStatusColor() {
      return this.hasJoinedCompany
        ? 'tw-bg-green-500'
        : this.isInvited
        ? 'tw-bg-orange-500'
        : 'tw-bg-red-500'
    },

    isTrashed() {
      return this.employment.status === 'Deleted'
    },

    isInviting() {
      if (!this.invitingEmployment) {
        return false
      }

      return this.invitingEmployment.id === this.employment.id
    },
  },

  methods: {
    editEmployment() {
      if (this.isTrashed) return

      this.$emit('edit-employment')
    },
  },
}
</script>
